/* unplugin-vue-components disabled */import __unplugin_components_1 from 'D:/Projects/118_meijia/frontend/src/components/MModal.vue';
import __unplugin_components_0 from 'D:/Projects/118_meijia/frontend/src/components/MIframe.vue';
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { resizeIframe } from '@/js/functions';
import { Customer, getApiBaseUrl, Upload } from "@/js/services/baseService";
import store from "@/store";
export default {
  __name: 'ServicePreviewModal',
  props: {
    visible: Boolean,
    title: String,
    orderId: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:visible', 'confirm'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var refIframe = ref(null);
    var src = computed(function () {
      return getApiBaseUrl("pdf/order/treatment?id=".concat(props.orderId), 0);
    });
    var now = new Date();
    var cData = reactive({
      iframeLoaded: false,
      iframeOpacity: 0,
      datetimeModalShow: false,
      signModalVisible: false,
      clientSignatureSrc: '',
      documentDate: now,
      documentTime: [now.getHours(), now.getMinutes()],
      dateTimeInfo: computed(function () {
        var date = new Date(cData.documentDate);
        return {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          date: date.getDate(),
          hours: cData.documentTime.length ? cData.documentTime[0] : 0,
          minutes: cData.documentTime.length ? cData.documentTime[1] : 0
        };
      })
    }); // 提供給iframe使用的資源

    var exposeToIframe = {
      goSign: goSign,
      selectDate: function selectDate() {
        cData.datetimeModalShow = true;
      },
      onSignConfrim: function onSignConfrim(src) {},
      onDateConfrim: function onDateConfrim(date) {},
      onTimeConfrim: function onTimeConfrim(date) {}
    };
    watch(function () {
      return cData.documentDate;
    }, function () {
      if ('function' === typeof exposeToIframe.onDateConfrim) {
        exposeToIframe.onDateConfrim(cData.documentDate);
      }
    });
    watch(function () {
      return cData.documentTime;
    }, function () {
      if ('function' === typeof exposeToIframe.onTimeConfrim) {
        exposeToIframe.onTimeConfrim(cData.documentTime);
      }
    });

    function goSign() {
      cData.signModalVisible = true;
    }

    function onConfirm(evt) {
      cData.signModalVisible = false;
      cData.clientSignatureSrc = evt;

      if ('function' === typeof exposeToIframe.onSignConfrim) {
        exposeToIframe.onSignConfrim(evt);
      }
    }

    function confirmSave() {
      appSwalCustomDialog.confirm({
        content: '確認儲存?',
        onOk: function onOk() {
          emit('update:visible', false);
          emit('confirm');
        }
      });
    }
    /**
     * @param {object} event 
     * @param {PostMessageData} event.data 
     */


    function onMessage(event) {
      var data = event.data;

      if (data) {
        if (data.data) {
          var data = data.data;

          switch (data.type) {
            case 'confirm':
              store.commit('setData', {
                appGlobalSpinnerShow: true
              });
              Customer.treatmentRecordSave({
                order_id: props.orderId
              }).then(function (response) {
                if (response.result == 1) {
                  appSwalCustomDialog.alert({
                    title: appSwalCustomDialog.ALERT_COMMON_TITLE,
                    content: '簽署完成'
                  });
                  console.log('dat2a', data);
                  emit('update:visible', false);
                  emit('confirm');
                } else {
                  appSwalCustomDialog.alert({
                    title: appSwalCustomDialog.ALERT_COMMON_TITLE,
                    content: response.message
                  });
                }
              }).finally(function () {
                store.commit('setData', {
                  appGlobalSpinnerShow: false
                });
              });
              break;

            case 'sign':
              var signData = "records/".concat(data.customer_code, "/").concat(data.order_id, "/").concat(data.who, ".png");
              Upload.base64({
                path: signData,
                file_name: "".concat(data.who, ".png"),
                base64: data.data
              }).then(function (data) {});
              break;

            case 'alert':
              appSwalCustomDialog.alert({
                title: appSwalCustomDialog.ALERT_COMMON_TITLE,
                content: data.data
              });
              break;
          }
        }
      }
    }

    function onIframeInit(event) {
      if (event.window) {}
    }

    return function (_ctx, _cache) {
      var _component_MIframe = __unplugin_components_0;

      var _component_MModal = __unplugin_components_1;

      return _openBlock(), _createBlock(_component_MModal, {
        visible: __props.visible,
        class: "service-preview-modal",
        "height-fill": "",
        "onUpdate:visible": _cache[3] || (_cache[3] = function ($event) {
          return _ctx.$emit('update:visible', $event);
        })
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_MIframe, {
            src: _unref(src),
            onMessage: _cache[0] || (_cache[0] = function ($event) {
              return onMessage($event);
            }),
            onInit: _cache[1] || (_cache[1] = function ($event) {
              return onIframeInit($event);
            }),
            onResizeError: _cache[2] || (_cache[2] = function ($event) {
              $event.pullHeight();
            })
          }, null, 8, ["src"])];
        }),
        _: 1
      }, 8, ["visible"]);
    };
  }
};